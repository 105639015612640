import React, { useContext, useState, MouseEvent } from 'react';
import { FlexColumn } from '../../App/Styles/Container.styled';
import questionSvg from '../../Assets/question.svg';
import loadboardPng from '../../Assets/123loadboard.png';
import truckStopIco from '../../Assets/truckStop.png';
import rateInsightsProductLogo from '../../Assets/rateInsightsProductLogo.png';
import FMCSA from '../../Assets/FMCSA.png';
import { VectorImage } from '../../App/Styles/Image.styled';
import { PluginContext } from '../../Context/Plugin/PluginContext';
import { Popup } from '../../Components/Popup/Popup';
import useChatApi from '../../Hooks/useApi/useChatApi';
import {
  AddPluginButton,
  AddPluginButtonAlternate,
  CenterContainer,
  ColumnRightTitle,
  Container,
  FlexColumnProviderContainer,
  FlexColumnRightLayout,
  FlexRowProvider,
  NonClickablePluginContainer,
  OfflineLamp,
  OnlineLamp,
  ParagraphBold20,
  ParagraphWithIcon,
  PluginContainerButton,
  PluginContainerRightbar,
  ResponsiveImage,
  ResponsiveParagraph,
  ResponsiveText,
  ThinLine,
} from './RightbarLayout.styled';

// Define the 123Loadboard Authorization URL
const REACT_APP_123LB_AUTH_URL =
  process.env.REACT_APP_123LB_AUTH_URL ||
  'https://api.dev.123loadboard.com/authorize';

// Truckstop Rate Insights Login URL function
const getTruckstopRateInsightsLoginURL = () => {
  const clientId = process.env.REACT_APP_TRUCKSTOP_RATINGS_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_TRUCKSTOP_RATINGS_REDIRECT_URL;

  if (!clientId || !redirectUri) {
    console.error(
      'Environment variables for Truckstop Rate Insights are missing.'
    );
    return '';
  }

  const data = {
    client_id: clientId,
    scope: 'truckstop',
    response_type: 'code',
    state: 'abcd',
    redirect_uri: redirectUri,
  };

  const params = new URLSearchParams(data as Record<string, string>).toString();
  return `https://api.truckstop.com/auth/authorize?${params}`;
};

export const get123LoadboardLoginURL = () => {
  const data = {
    client_id: process.env.REACT_APP_123LB_CLIENT_ID!,
    scope: 'loadsearching ratecheck',
    resource: '0',
    response_type: 'code',
    state: '0',
    redirect_uri: process.env.REACT_APP_123LB_REDIRECT_URL!,
  };
  const params = new URLSearchParams(data).toString();
  return `${REACT_APP_123LB_AUTH_URL}?${params}`;
};

const RightbarLayout = () => {
  const pluginContext = useContext(PluginContext);
  const { loginTruckstop } = useChatApi();
  const [enablePopup, setEnablePopup] = useState(false);

  const handleTruckstopSubmit = async (data: string) => {
    // Send Integration ID
    const res = await loginTruckstop(data);

    console.log(res);
    // Check if valid response
    if (res !== null && res !== undefined) {
      if (res.success) pluginContext!.setTruckstopPlugin(true);
    }
  };

  // Turn truckstop on and off
  const toggleTruckstop = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    pluginContext!.setTruckstopPlugin(!pluginContext!.truckstopPlugin);
  };

  return (
    <Container>
      {enablePopup ? (
        <Popup
          title="Enter Your Integration ID"
          subtitle="For more information go to https://truckstop.com"
          handleExit={() => setEnablePopup(false)}
          handleSubmit={handleTruckstopSubmit}
          hasInput={true}
        />
      ) : null}

      <CenterContainer>
        <FlexColumnRightLayout justify="space-between" alignItems="center">
          <ColumnRightTitle bold={false}>Manage Providers</ColumnRightTitle>

          <AddPluginButton>
            <ParagraphBold20>Add Provider</ParagraphBold20>
          </AddPluginButton>

          <AddPluginButtonAlternate>
            <FlexRowProvider justify="center">
              <ParagraphWithIcon bold={false}>
                Your Providers <VectorImage src={questionSvg} />
              </ParagraphWithIcon>
            </FlexRowProvider>
          </AddPluginButtonAlternate>

          <FlexColumnProviderContainer
            justify="flex-start"
            width="100%"
            gap="1rem"
          >
            {/* Truckstop Plugin */}
            <PluginContainerButton
              onClick={() => setEnablePopup(true)}
              onContextMenu={toggleTruckstop}
              $enabled={true}
            >
              <ResponsiveImage src={truckStopIco} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>Truckstop</ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.truckstopPlugin ? (
                <OnlineLamp />
              ) : (
                <OfflineLamp />
              )}
            </PluginContainerButton>

            {/* Truckstop Rate Insights Plugin */}
            {/* <PluginContainerButton
              onClick={() => (window.location.href = getTruckstopRateInsightsLoginURL())}
              $enabled={true}
            >
              <ResponsiveImage src={rateInsightsProductLogo} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>Truckstop Rate Insights</ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.truckstopRateInsightsPlugin ? <OnlineLamp /> : <OfflineLamp />}
            </PluginContainerButton> */}

            {/* 123Loadboard Plugin */}
            <PluginContainerRightbar
              to={get123LoadboardLoginURL()}
              $enabled={true}
              $hasBorder
            >
              <ResponsiveImage src={loadboardPng} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>
                  123Loadboard
                </ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.LB123Plugin ? <OnlineLamp /> : <OfflineLamp />}
            </PluginContainerRightbar>

            <ThinLine />

            <NonClickablePluginContainer>
              <ResponsiveImage src={FMCSA} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>FMCSA</ResponsiveParagraph>
                <ResponsiveText>Company Analysis</ResponsiveText>
              </FlexColumn>
              <OnlineLamp />
            </NonClickablePluginContainer>
          </FlexColumnProviderContainer>
        </FlexColumnRightLayout>
      </CenterContainer>
    </Container>
  );
};

export default RightbarLayout;
