export const lightPalette = {
  primary: '#FFFFFF',
  secondary: '#f4f4f4',
  filterButtons: '#000000',
  text: '#747474',
  detailsText: '#000000',
  alternateText: 'white',
  redBackground: '#C82333',
  sidemenu: '#f4f4f4',
  sideMenuAccent: '#ffffff',
  priceText: '#22902b',
  title: '#171717',
  accent: '#4d4d4d',
  hover: '#dfdfdf',
  arrowIcon: '#000000',
  arrowIconHovered: '#5b5151',
  settingsBorderColor: '#e2d8d8',
  settingsBorderOutlineColor: '#80808080',
  settingsDisabledBg: '#dadada',
  settingsEnabledBg: '#f2f2f2',
  alternativeWhite: '#5c5c5c',
  promptText: '',
  schedulerPromptText: '#4c4c4c',
  schedulerIDText: '#555555',
  schedulerStatusText: '#525252',
  schedulerButtonBg: '#727070',
  schedulerHoverBg: '#555353',
  schedulerNavButtonBg: '#727070',
  schedulerWhite: '#bdbdbd',
  schedulerActiveStatusText: '#00c000',
  schedulerInactiveStatusText: '#b64a4ae0',
  schedulerCompletedStatusText: '#889835b8',
  schedulerExpiredStatusText: '#c60606',
  profileButtonColor: '#ffffef',
  profileButtonBackgroundColor: '#171717',
  hoverAlternativeColor: 'gray',
  userProfileCardBorder: '#e0ded8',
  userProfileActiveBorder: '#77777757',
  buttonText: 'white',
  darkBackground: '#e6e6e6',
  filter:
    'invert(48%) sepia(10%) saturate(270%) hue-rotate(201deg) brightness(95%) contrast(97%)',
  primaryFilter:
    'invert(100%) sepia(11%) saturate(156%) hue-rotate(114deg) brightness(109%) contrast(80%)',
  activeFilter:
    'invert(3%) sepia(2%) saturate(6850%) hue-rotate(218deg) brightness(95%) contrast(83%)',
  accentFilter:
    'invert(39%) sepia(95%) saturate(1748%) hue-rotate(201deg) brightness(100%) contrast(103%)',
  transition: '0.3s ease-in-out all',
  shadowColor: '0px 0px 10px 2px rgba(49, 49, 49, 0.48)',
  borderColor: '1px solid #535353',
};

export const darkPalette = {
  primary: '#000000',
  secondary: '#191919',
  filterButtons: '#AD8449',
  text: '#f4f4f4',
  detailsText: '#C7C7C7',
  alternateText: '#747474',
  redBackground: '#883837',
  sidemenu: '#111111',
  sideMenuAccent: '#1A1A1A',
  priceText: '#8BFFCF',
  title: '#AD8449',
  accent: '#AD8449',
  hover: '#ffffff29',
  arrowIcon: '#C7C7C7',
  arrowIconHovered: '#AD8449',
  settingsBorderColor: '#2a2727',
  settingsBorderOutlineColor: '#2a2727',
  settingsDisabledBg: '#9c9c9c',
  settingsEnabledBg: 'lightgray',
  alternativeWhite: '#f3f2f2',
  promptText: '#b3b0b0',
  schedulerPromptText: '#b3b0b0',
  schedulerIDText: '#6b6b6b',
  schedulerStatusText: '#b6b5b5',
  schedulerButtonBg: '#793737',
  schedulerHoverBg: '#d66c4bbd',
  schedulerNavButtonBg: '#ad8449',
  schedulerWhite: '#bdbdbd',
  schedulerActiveStatusText: '#4db14dd1',
  schedulerInactiveStatusText: '#881602',
  schedulerCompletedStatusText: '#9caf3ea8',
  schedulerExpiredStatusText: '#520d00',
  profileButtonColor: '#171717',
  profileButtonBackgroundColor: '#ffffef',
  hoverAlternativeColor: '#c3c3c3',
  userProfileCardBorder: '#e0ded857',
  userProfileActiveBorder: '#92929257',
  buttonText: 'black',
  darkBackground: '#141313',
  filter:
    'invert(50%) sepia(23%) saturate(155%) hue-rotate(180deg) brightness(85%) contrast(85%)',
  primaryFilter:
    'invert(4%) sepia(6%) saturate(2088%) hue-rotate(187deg) brightness(94%) contrast(92%)',
  activeFilter:
    'invert(100%) sepia(26%) saturate(1324%) hue-rotate(309deg) brightness(103%) contrast(110%)',
  inActiveFilter:
    'invert(46%) sepia(11%) saturate(0%) hue-rotate(130deg) brightness(104%) contrast(97%)',
  accentFilter:
    'invert(36%) sepia(12%) saturate(3193%) hue-rotate(216deg) brightness(97%) contrast(94%)',
  transition: '0.3s ease-in-out all',
  shadowColor: '0px 0px 10px 2px rgba(49, 49, 49, 0.48)',
  borderColor: '1px solid #535353',
};
