import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthenticationContext } from '../../../Context/Auth/AuthContext';
import useAuthenticationApi from '../../../Hooks/useApi/useAuthenticationApi';
import { ITokens } from '../../../Models/UserModel';
import SignUpCard from './SignUpCard';
import LoginCard from './LogInCard';
import styled from 'styled-components';
import { IResponse } from '../../../Models/BaseModel';
import NavigationLoginLayout from '../../../Layouts/Navigation/NavigationLogInLayout';
import BottombarLayout from '../../../Layouts/Bottombar/BottombarLayout';
import useRegisterApi from '../../../Hooks/useApi/useRegisterApi';
import { sizes } from '../../../Utils/screenUtil';
import { PluginContext } from '../../../Context/Plugin/PluginContext';

const LoginPage = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthenticationContext);
  const pluginContext = useContext(PluginContext);
  const [searchParams] = useSearchParams();
  const { loginApi } = useAuthenticationApi();
  const { registerUser } = useRegisterApi();

  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>('');
  const [successMessage, setSuccess] = useState<string>('');
  const [mcNumber, setMcNumber] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>('');
  const [dotNumber, setDotNumber] = useState<string | null>('');
  const [phone, setPhone] = useState<string | null>(null);
  const [provider, setProvider] = useState<string | null>(null);
  const [isLoginView, setIsLoginView] = useState<boolean>(
    searchParams.get('view') !== 'signup'
  );

  const isLoggedIn = authContext?.tokens
    ? ' Note: You are already logged in!'
    : '';

  const makeLogin = async () => {
    if (isLoading) return;
    if (email !== null && password !== null) {
      setLoading(true);
      const response = await loginApi(email, password);
      setLoading(false);
      if (response !== null) {
        const data = response.data as IResponse<ITokens>;
        if (data.success) {
          console.log(response, 'response');
          authContext?.loginListener(data.data);
          localStorage.setItem('userData', JSON.stringify(data.data));
          pluginContext?.checkTruckStop();
          navigate('/');
        } else {
          setError('Invalid email and/or password.');
        }
      } else {
        setError('Error logging in, try again or report this error.');
      }
    } else {
      setError('Please enter an email and/or password.');
    }
  };

  const handleRegister = async () => {
    if (!firstName) return setError('Missing your first name');
    if (!lastName) return setError('Missing your last name');
    if (!email) return setError('Missing your email');
    if (!password) return setError('Missing your password');
    if (!mcNumber) return setError('Missing your MC number');
    if (!dotNumber) return setError('Missing your DOT number');
    if (password.length < 8)
      return setError('Password must be at least 8 characters');

    const isBroker = provider ? provider === 'Broker' : true;

    setLoading(true);
    const message = await registerUser({
      firstName,
      lastName,
      email,
      password,
      dotNumber,
      isBroker,
      company,
      phone,
      mcNumber,
    });
    if (message) setError(message);
    else
      setSuccess(
        'Thank you for signing up! We’re excited to have you on board. Please check your inbox for a confirmation email to complete your registration.'
      );
    setLoading(false);
  };

  const handleViewSwitch = (isLogin: boolean) => {
    setError('');
    setIsLoginView(isLogin);
  };

  return (
    <PageContainer>
      <NavigationLoginLayout />
      {isLoginView ? (
        <CardContainer>
          <LoginCard
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            loading={isLoading}
            errorMessage={errorMessage}
            isLoggedIn={isLoggedIn}
            makeLogin={makeLogin}
            setError={setError}
            setIsLoginView={() => handleViewSwitch(false)}
          />
        </CardContainer>
      ) : (
        <CardContainer>
          <SignUpCard
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            mcNumber={mcNumber}
            setMcNumber={setMcNumber}
            phone={phone}
            setPhone={setPhone}
            company={company}
            setCompany={setCompany}
            dotNumber={dotNumber}
            setDotNumber={setDotNumber}
            provider={provider}
            setProvider={setProvider}
            successMessage={successMessage}
            setSuccess={setSuccess}
            errorMessage={errorMessage}
            setError={setError}
            loading={isLoading}
            handleRegister={handleRegister}
            setIsLoginView={() => handleViewSwitch(true)}
          />
        </CardContainer>
      )}
      <BottombarLayout />
    </PageContainer>
  );
};

export default LoginPage;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
`;

const CardContainer = styled.div`
  padding-left: 7%;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than tablets */
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
`;
