/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomCircularLoading } from '../../../Components/CustomCircularLoading/CustomCircularLoading';

// Assuming you have these interfaces defined in your project
import {
  IMainRoute,
  IStop,
  IStopDetails,
} from '../../../Models/RoutePlannerModel';
import useLoadsApi from '../../../Hooks/useApi/useLoadsApi';
import { normalizeString } from '../../../Utils/normalizeString';
import RouteEmailDraftModal from '../../../Components/Modal/RouteEmailDraftModal';
// import { ILoad } from '../../../Models/LoadModel';
import { useGetUserProfile } from '../../../Hooks/useApi/useAuthenticationApi';
import loadboardPng from '../../../Assets/123loadboard_2.png';
import truckStopIco from '../../../Assets/truckStop.png';
import { Image } from '../../../App/Styles/Image.styled';

interface StopCardProps {
  stop: IStop;
  mainRouteData: IMainRoute;
}

const StopCard: React.FC<StopCardProps> = ({ stop, mainRouteData }) => {
  const { getMultipleProviderDetailsAPI } = useLoadsApi();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [detailData, setDetailData] = useState<IStopDetails>();
  const { userProfileData } = useGetUserProfile();
  console.log('stop =>', stop);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await getMultipleProviderDetailsAPI(
          stop.boardId,
          stop.externalId
        );
        if (data.status >= 400 && data.status < 600) {
          throw new Error(`HTTP error! status: ${data.status}`);
        }

        setDetailData(data?.data);
      } catch (err) {
        setError('Failed to load details');
        console.error('Error fetching details:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const overallCost =
    stop.rpm && stop.mileage
      ? Math.round(stop.rpm * stop.mileage * 100) / 100
      : 'N/A';

  const formattedRpm =
    stop.rpm !== null && stop.rpm !== undefined
      ? Number.isInteger(stop.rpm)
        ? stop.rpm
        : stop.rpm.toFixed(2)
      : 'N/A';

  let providerTypeNumber = stop?.boardId;
  let srcIcon;

  if (providerTypeNumber === 2) srcIcon = loadboardPng;
  else if (providerTypeNumber === 3) srcIcon = truckStopIco;
  else srcIcon = '';

  if (isLoading) {
    return (
      <StopRouteContainer>
        <LoadingContainer>
          <CustomCircularLoading />
        </LoadingContainer>
      </StopRouteContainer>
    );
  }

  if (!!error) {
    return (
      <StopRouteContainer>
        <ErrorContainer>
          <ErrorTitle>Something went wrong</ErrorTitle>
          <ErrorSubtitle>Please try again later</ErrorSubtitle>
        </ErrorContainer>
      </StopRouteContainer>
    );
  }

  return (
    <StopRouteContainer>
      <MiddleRouteTitleContainer>
        <LocationPair>
          <Location>
            <CityText>{stop.origin?.city || 'N/A'}</CityText>
            <StateText>{stop.origin?.state || 'N/A'}</StateText>
          </Location>
          <Dash>-</Dash>
          <Location>
            <CityText>{stop.destination?.city || 'N/A'}</CityText>
            <StateText>{stop.destination?.state || 'N/A'}</StateText>
          </Location>
        </LocationPair>
      </MiddleRouteTitleContainer>
      <BodyContainer>
        <KeyValueContainer>
          <KeyValueItem>
            <Key>Age:</Key>
            <Value>{detailData?.published_at || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Price:</Key>
            <IncomeText>
              {overallCost !== 'N/A' && formattedRpm !== 'N/A'
                ? `$${overallCost} (RPM ${formattedRpm})`
                : 'N/A'}
            </IncomeText>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Mileage:</Key>
            <Value>{stop.mileage || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Pickup:</Key>
            <Value>{detailData?.pickup_dt || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Equipment:</Key>
            <Value>{detailData?.equipment_type || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Weight:</Key>
            <Value>{detailData?.weight || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Company:</Key>
            <Value>{normalizeString(detailData?.company_name) || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>MC Number:</Key>
            <Value>{stop.mc_number || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Contact:</Key>
            <Value>{normalizeString(detailData?.contact_name) || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Phone:</Key>
            <Value>{detailData?.phone || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Email:</Key>
            <Value>{detailData?.email || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Description:</Key>
            <Value>{normalizeString(detailData?.description) || 'N/A'}</Value>
          </KeyValueItem>
          <KeyValueItem>
            <Key>Notes:</Key>
            <Value>{normalizeString(detailData?.description) || 'N/A'}</Value>
          </KeyValueItem>
        </KeyValueContainer>
      </BodyContainer>
      <ButtonFooter>
        <StyledButton onClick={() => setModalIsOpen(true)}>
          Send email
        </StyledButton>

        <DisabledOutlineButton>Dial</DisabledOutlineButton>
        <DisabledOutlineButton>Book</DisabledOutlineButton>
      </ButtonFooter>
      {userProfileData && (
        <RouteEmailDraftModal
          mainRouteData={mainRouteData}
          stopData={stop}
          stopDetailData={detailData}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          userProfileData={userProfileData}
        />
      )}
      <IconContainerAbsolute src={srcIcon}></IconContainerAbsolute>
    </StopRouteContainer>
  );
};

// Styled Components

const StopRouteContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom right, #2a2a2a,rgb(19, 19, 19))`};
  border-top: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-left: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-right: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-bottom: 0.5px solid ${(props) => `${props.theme.detailsText}30`};
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #555;
  width: 350px; // Ensures each block has a minimum width for visibility
  flex-shrink: 0; // Prevents the blocks from shrinking when scrolling
  height: 400px;
  margin-bottom: 25px;
`;

const MiddleRouteTitleContainer = styled.div`
  font-weight: bold;
  text-align: center; // Center the content
  color: #d4af37; // Gold color for the title
  display: flex;
  justify-content: center; // Center the LocationPair horizontally
  align-items: center; // Center the LocationPair vertically
  //   border: 1px solid blue;
`;

const LocationPair = styled.div`
  display: flex;
  justify-content: center; // Center the locations horizontally
  align-items: center; // Align items vertically
  width: 100%;
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; // Center the city and state vertically
`;

const Dash = styled.span`
  margin: 0 10px; // Adjust the margin to bring the locations closer
  font-size: 1em; // Make the dash slightly bigger to match the image
  color: #8c8c8c;
`;

const CityText = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #8c8c8c;
`;

const StateText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: white;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 320px; // Adjust this height as needed
  overflow-y: auto; // Enable vertical scrolling
  padding: 8px 0 2px 0;
  // border: 1px solid blue;
`;

const KeyValueContainer = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: flex-start;
  height: 320px; // Adjust this height as needed
  overflow-y: auto; // Enable vertical scrolling
  padding: 2.5px 0 2.5px 5px;
`;

const KeyValueItem = styled.div`
  display: flex;
  flex-wrap: wrap; // Ensures items wrap to the next line if needed
  align-items: flex-start; // Aligns content properly
  margin-bottom: 5px;
  font-size: 14px;
  gap: 5px; // Adds spacing between key and value
`;
const Key = styled.span`
  color: ${({ theme }) => theme.title};
  font-weight: 500;
  white-space: nowrap; // Prevents the key from wrapping
`;

const Value = styled.span`
  color: ${({ theme }) => theme.alternativeWhite};
  margin-left: 5px; // Small margin to separate key from value
  max-width: 220px;
`;

const IncomeText = styled.span`
  color: ${({ theme }) => theme.priceText};
  margin-left: 5px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
`;

const StyledButton = styled.button`
  text-transform: none;
  font-size: 12px;
  background-color: ${({ theme }) => theme.title};
  color: ${({ theme }) => theme.primary};
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  transition: ${(props) => (props.disabled ? 'none' : 'background-color 0.3s')};
  margin-left: 5px; // Small margin to the left for spacing

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.hoverAlternativeColor : theme.title};
  }
`;

const DisabledOutlineButton = styled.button`
  text-transform: none;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.title};
  color: ${({ theme }) => theme.title};
  background-color: transparent;
  padding: 8px 24px;
  cursor: not-allowed;
  transition: none;
  opacity: 0.5;
  margin-left: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.title};
  }
  pointer-events: none;
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.redBackground};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorTitle = styled.div`
  font-size: 16px; // Matches the original ErrorContainer font size
  color: tomato;
  margin-bottom: 10px; // Space between title and subtitle
`;

const ErrorSubtitle = styled.div`
  font-size: 14px;
  color: #8c8c8c; // A lighter shade for the subtitle
  text-align: center;
`;

const IconContainerAbsolute = styled(Image)`
  position: absolute;
  top: 4px;
  right: 3px;
  width: 40px;
  height: 40px;
`;

export default StopCard;
