import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { IScheduleItem, Status } from '../../Models/SchedulerModel';
import { useDeactivatePrompt } from '../../Hooks/useApi/useSchedulerApi';
import { CircularProgress } from '@mui/material';
import { getStatusColor } from '../../Utils/getStatusColor';
import { Paragraph, TextSpan } from '../../App/Styles/Typography.styled';
import { sizes } from '../../Utils/screenUtil';

interface PromptCardProps {
  singlePrompt: IScheduleItem;
}

export const PromptCard: React.FC<PromptCardProps> = ({ singlePrompt }) => {
  const { deactivatePromptFn, deactivateIsLoading } = useDeactivatePrompt();
  const navigate = useNavigate();
  const isActive = singlePrompt.status === Status.Active;
  const isCompleted = singlePrompt.status === Status.Completed;
  const isExpired = singlePrompt.status === Status.Expired;
  return (
    <CardContainer>
      <CardHeader>
        <PromptEmail>{singlePrompt.email}</PromptEmail>
        <PromptStatus
          isActive={isActive}
          isCompleted={isCompleted}
          isExpired={isExpired}
        >
          {singlePrompt.status}
        </PromptStatus>
      </CardHeader>
      <CardBody>
        <PromptText>
          <PromptSpan>Your prompt: </PromptSpan>
          {singlePrompt.prompt_text}
        </PromptText>
      </CardBody>
      <CardFooter>
        <FooterLeft>
          <DateText>
            Created: {new Date(singlePrompt.created_at).toLocaleString()}
          </DateText>
          <DateText>
            Updated: {new Date(singlePrompt.updated_at).toLocaleString()}
          </DateText>
        </FooterLeft>
        <FooterRight>
          {deactivateIsLoading ? (
            <CircularProgress size={40} color="warning" />
          ) : (
            <ActivationButton
              onClick={() => deactivatePromptFn(singlePrompt.id)}
              disabled={!isActive || isCompleted}
            >
              Deactivate
            </ActivationButton>
          )}
          {isCompleted && (
            <SendPromptButton
              onClick={() =>
                navigate(`/?scheduled_prompt=${singlePrompt.prompt_text}`)
              }
              disabled={isActive}
            >
              Check out prompt
            </SendPromptButton>
          )}
        </FooterRight>
      </CardFooter>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.darkBackground};
  border-radius: 12px;
  padding: 16px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-left: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-right: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-bottom: 0.5px solid ${({ theme }) => `${theme.detailsText}30`};
  width: 100%;
  @media only screen and (max-width: 700px) {
    width: 550px;
  }
  @media only screen and (max-width: 630px) {
    width: 500px;
  }
  @media only screen and (max-width: 580px) {
    width: 450px;
  }
  @media only screen and (max-width: 540px) {
    width: 420px;
  }
  @media only screen and (max-width: 485px) {
    width: 390px;
  }
  @media only screen and (max-width: 450px) {
    width: 360px;
  }
  @media only screen and (max-width: 415px) {
    width: 340px;
  }
  @media only screen and (max-width: 400px) {
    width: 320px;
  }
  @media only screen and (max-width: 375px) {
    width: 295px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const PromptStatus = styled(TextSpan)<{
  isActive: boolean;
  isCompleted: boolean;
  isExpired: boolean;
}>`
  font-size: 25px;
  font-weight: bold;
  color: ${({ theme, ...statusProps }) => getStatusColor(theme, statusProps)};
  @media only screen and (max-width: ${sizes.desktopS}) {
    /* Styles for screens less than 1920px */
    font-size: 22px;
  }
  @media only screen and (max-width: ${sizes.desktop}) {
    /* Styles for screens less than 2560px */
    font-size: 17px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 15px;
  }
`;

const CardBody = styled.div`
  flex-grow: 1;
  margin-bottom: 16px;
`;

const PromptText = styled(Paragraph)`
  font-size: 25px;
  color: ${({ theme }) => theme.alternativeWhite};
  margin: 0;
  font-style: italic;
  word-wrap: break-word; /* Forces words to wrap */
  @media only screen and (max-width: ${sizes.desktop}) {
    /* Styles for screens less than 2560px */
    font-size: 18px;
  }
  @media only screen and (max-width: ${sizes.laptopMid}) {
    /* Styles for screens less than 1250px */
    font-size: 16px;
  }
`;

const PromptSpan = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.schedulerPromptText};
  margin-right: 2px;
  font-weight: bold;
`;

const PromptEmail = styled.span`
  font-size: 22px;
  color: ${({ theme }) => theme.schedulerStatusText};
  @media only screen and (max-width: ${sizes.desktop}) {
    /* Styles for screens less than 2560px */
    font-size: 14px;
  }
  @media only screen and (max-width: ${sizes.laptopMid}) {
    /* Styles for screens less than 1250px */
    font-size: 12px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.schedulerStatusText};
`;

const DateText = styled(TextSpan)`
  margin-top: 4px;
  font-size: 16px;
  @media only screen and (max-width: ${sizes.desktop}) {
    /* Styles for screens less than 2560px */
    font-size: 10px;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${({ theme }) => theme.schedulerStatusText};
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: flex-end; /* Push the button to the right */
  align-items: flex-end; /* Align button to the bottom */
`;

const ActivationButton = styled.button<{ disabled: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  padding: 6px 10px;
  background-color: ${({ theme }) => theme.schedulerButtonBg};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.schedulerHoverBg};
  }
`;

const SendPromptButton = styled.button<{ disabled: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  padding: 6px 10px;
  background-color: ${({ theme }) => theme.schedulerNavButtonBg};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.schedulerHoverBg};
  }
`;
