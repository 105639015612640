import { createContext, useEffect, useState } from 'react';
import useChatApi from '../../Hooks/useApi/useChatApi';
import { ChildrenProps } from '../index';

interface IPluginContext {
  LB123Plugin: boolean;
  setLB123Plugin: (value: boolean) => void;
  truckstopPlugin: boolean;
  setTruckstopPlugin: (value: boolean) => void;
  checkTruckStop: () => void;
  truckstopRateInsightsPlugin: boolean; // Added this line
  setTruckstopRateInsightsPlugin: (value: boolean) => void; // Added this line
}

const PluginContext = createContext<IPluginContext | null>(null);

const PluginContextProvider = ({ children }: ChildrenProps) => {
  const { checkTruckstopIntegration } = useChatApi();
  const [, setCheckTruckStop] = useState<boolean>(true);
  const [LB123Plugin, setLB123Plugin] = useState<boolean>(false);
  const [truckstopPlugin, setTruckstopPlugin] = useState<boolean>(false);
  // const [truckstopRateInsightsPlugin, setTruckstopRateInsightsPlugin] =
  //   useState<boolean>(false);

  // ** Refresh truckstop activation every time it deactives **
  useEffect(() => {
    (async () => {
      const isEnabled = await checkTruckstopIntegration();
      console.log('isEnabled', isEnabled);
      setTruckstopPlugin(isEnabled);
      setCheckTruckStop(false);
    })();
  }, [checkTruckstopIntegration]);

  const contextData = {
    LB123Plugin: LB123Plugin,
    setLB123Plugin: (value: boolean) => setLB123Plugin(value),
    truckstopPlugin: truckstopPlugin,
    setTruckstopPlugin: (value: boolean) => setTruckstopPlugin(value),
    // truckstopRateInsightsPlugin: truckstopRateInsightsPlugin, // Added this line
    // setTruckstopRateInsightsPlugin: (value: boolean) =>
    //   setTruckstopRateInsightsPlugin(value), // Added this line
    checkTruckStop: () => setCheckTruckStop(true),
  } as IPluginContext;

  return (
    <PluginContext.Provider value={contextData}>
      {children}
    </PluginContext.Provider>
  );
};

export { PluginContext, PluginContextProvider };
