/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

import { VectorImage } from '../../App/Styles/Image.styled';
import {
  HeadTitle,
  Paragraph as OriginalParagraph,
} from '../../App/Styles/Typography.styled';
import logoSvg from '../../Assets/logo.svg';
import { sizes } from '../../Utils/screenUtil';
import { ProvidersDropdown } from '../../Components/ProvidersDropdown/ProvidersDropdown';
import ProfileDropdown from '../../Components/ProfileDropdown/ProfileDropdown';
import { useSettings } from '../../Context/Settings/useSettings';

interface NavigationLayoutProps {
  toggleLeftbar: () => void;
}

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  toggleLeftbar,
}) => {
  const { fetchSettings } = useSettings();

  // ** Fetch for Settings as soon as NavBar mounts ** //
  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Container>
      <HamburgerDiv onClick={toggleLeftbar}>
        <HamburgerIcon sx={{ fontSize: '50px' }} />
      </HamburgerDiv>
      <LogoContainer to="/">
        <VectorImage src={logoSvg} />
        <HeadTitle bold={true}>TarmacAI</HeadTitle>
      </LogoContainer>
      <ProvidersDropdown />
      {/* This dropdown appears on tablet screens or less */}
      <LinkContainer
        href="https://tarmac-ai.com"
        gridColumn={2}
        target="_blank"
        rel="noopener noreferrer"
        mobileDisplayed={true}
      >
        <Paragraph>COMPANY</Paragraph>
      </LinkContainer>
      {/* <LinkContainer */}
      {/*     href="https://docs.google.com/forms/d/e/1FAIpQLSfK1O1lH9EDGrCZlCkyMaJ4CmzZBnF6onIcAhSEyQvr80IGLg/viewform" */}
      {/*     gridColumn={3} target="_blank" rel="noopener noreferrer" */}
      {/* > */}
      <LinkContainer href="/contact" gridColumn={3}>
        <Paragraph>CONTACT</Paragraph>
      </LinkContainer>
      <LinkContainer href="/help" gridColumn={4}>
        <Paragraph>HELP</Paragraph>
      </LinkContainer>
      <ProfileContainer>
        <ProfileDropdown />
      </ProfileContainer>
    </Container>
  );
};

export default NavigationLayout;

const HamburgerDiv = styled.div`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.arrowIcon};
  bottom: -60px;
  left: -10px;
  display: none;

  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than tablet pixels */
    display: block;
  }
`;

const Paragraph = styled(OriginalParagraph)`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.detailsText};
`;

const Container = styled.div`
  grid-area: nav;
  position: relative;
  width: 100%;
  margin-left: 1rem;
  display: grid;
  gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-columns: minmax(50px, auto);
  grid-template-rows: 1fr;
  border-bottom: 2px solid rgba(178, 165, 165, 0.18);
  height: 4rem;
  background-color: ${({ theme }) => theme.primary};
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  grid-column: 1;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;

const LinkContainer = styled.a<{
  gridColumn: number | null;
  mobileDisplayed?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: ${(props) => (props?.gridColumn ? props.gridColumn : 1)};
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    display: none};
  }
`;

const ProfileContainer = styled.div`
  color: ${({ theme }) => theme.text};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  margin-right: 2rem;
  gap: 1.5rem;
  grid-column: 6;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;

const HamburgerIcon = styled(MenuIcon)`
  color: ${({ theme }) => theme.alternativeWhite};
`;
