import { useEffect, useState } from 'react';
import NavigationLayout from '../../Layouts/Navigation/NavigationLayout';
import LeftbarLayout from '../../Layouts/Leftbar/LeftbarLayout';
import ContentLayout from '../../Layouts/Content/ContentLayout';
import RightbarLayout from '../../Layouts/Rightbar/RightbarLayout';
import { Container } from './MainOutlet.styled';

const MainOutlet = () => {
  // const { user, fetchUserDetails } = useUserContext();
  // const { userData, userDataIsLoading } = useGetUser();

  const [isLeftbarOpen, setLeftbarOpen] = useState<boolean>(false);
  const [isLeftbarTransitioning, setIsLeftbarTransitioning] =
    useState<boolean>(false);

  useEffect(() => {
    // Define a media query to check if the screen width is greater than 2240px
    const mediaQuery = window.matchMedia('(min-width: 2240px)');

    // Function to update the state based on media query change
    const handleMediaChange = (e: MediaQueryListEvent | MediaQueryList) => {
      setLeftbarOpen(e.matches);
    };

    // Set initial state based on the current media query status
    handleMediaChange(mediaQuery);

    // Add event listener for media query change
    mediaQuery.addEventListener('change', handleMediaChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, []);

  const toggleLeftbar = () => {
    const transitionDuration = 250;
    if (isLeftbarOpen) {
      setIsLeftbarTransitioning(true);
      setTimeout(() => {
        setLeftbarOpen(false);
        setIsLeftbarTransitioning(false);
      }, transitionDuration);
    } else {
      setIsLeftbarTransitioning(true);
      setLeftbarOpen(true);
      setTimeout(() => {
        setIsLeftbarTransitioning(false);
      }, transitionDuration);
    }
  };

  return (
    <Container isLeftbarOpen={isLeftbarOpen}>
      <NavigationLayout toggleLeftbar={toggleLeftbar} />
      <LeftbarLayout
        $isLeftbarOpen={isLeftbarOpen}
        $isLeftbarTransitioning={isLeftbarTransitioning}
        toggleLeftbar={toggleLeftbar}
      />
      <ContentLayout />
      <RightbarLayout />
    </Container>
  );
};

export default MainOutlet;
