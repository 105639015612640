import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useChatApi from '../../../Hooks/useApi/useChatApi';
import { PluginContext } from '../../../Context/Plugin/PluginContext';

const LoginRedirectRatingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { truckStopRatingLogin } = useChatApi(); // Updated hook call
  const pluginContext = useContext(PluginContext);

  useEffect(() => {
    console.log('Start effect for Ratings Redirect');
    (async () => {
      try {
        const code = queryParams.get('code');
        console.log('Code:', code);

        if (!code) {
          console.log('Code is missing');
          navigate('/');
          return;
        }

        // Call the truckStopRatingLogin API
        const data = await truckStopRatingLogin(
          code,
          'https://dev.tarmac-ai.com/copilot/oauth/callback/truckstop'
        );

        if (data === null) {
          console.log('Data is null');
          navigate('/');
          return;
        }

        console.log('Data received:', data);

        // Update the plugin context
        pluginContext?.setTruckstopRateInsightsPlugin(true); // Set the truckstopRateInsightsPlugin to true

        navigate('/'); // Redirect after successful processing
      } catch (error) {
        console.error('An error occurred:', error);
        navigate('/'); // Navigate to home in case of error
      }
    })();
  }, [queryParams, navigate, truckStopRatingLogin, pluginContext]);

  return (
    <div>
      <h2>Redirecting...</h2>
    </div>
  );
};

export default LoginRedirectRatingsPage;
